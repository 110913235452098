import { columnToFilter } from "constants/columns";
import { LIST_TYPE } from "constants/common";
import { filterValues, quotesColumnToFilter } from "constants/quotesColumns";
import cdzColumns from "data/CDZColumns";
import { countryCodes } from "data/countries";
import quotesColumns from "data/QuotesColumns";
import { getFormattedDate } from "helpers/oldShipmentsGetFormattedDate.js";
import includes from "lodash/includes";
import React from "react";
import { useIntl } from "react-intl";
import get from "utils/get";
import { shipmentPhasesLabels } from "../../../data/shipmentDetailTypes";
import { messages } from "./messages";

export const getMaxWeight = () => {
  if (window === undefined) return 32;
  for (let i = 65; i < 440; i = i + 3) {
    if (window.matchMedia("(max-width:" + i + "rem)").matches) {
      return i > 75 ? i / 1.8 : i / 2;
    }
  }
  return 225;
};

export const getFittableColumnCount = (columns, module = "shipments") => {
  let maxWeight = getMaxWeight();
  let count = 0;
  let index = 0;
  const moduleData = module === "quotes" ? quotesColumns : cdzColumns;
  while (maxWeight > 0) {
    const currentSize = get(
      moduleData,
      columns[index++] + ".customSize",
      false
    );
    if (currentSize) {
      maxWeight = maxWeight - currentSize;
      if (maxWeight >= 0) {
        count++;
      } else {
        break;
      }
    } else {
      break;
    }
  }
  return count;
};

export const getIsDate = (item, module) => {
  if (module === LIST_TYPE.QUOTES) {
    return includes(
      ["CreationDate", "ExpirationDate", "EstimatedPickupDate"],
      item
    );
  } else {
    return includes(
      [
        "Departure",
        "Arrival",
        "EstimatedPickup",
        "ActualPickup",
        "EstimatedDeparturePort",
        "ActualDeparturePort",
        "EstimatedArrivalPort",
        "ActualArrivalPort",
        "EstimatedCustomerDelivery",
        "DocumentHandover",
      ],
      item
    );
  }
};

const isDateRange = (obj) => {
  if (typeof obj !== "object" || obj === null) return false;
  return obj.from && obj.to;
};

const mapFilterValueToRender = (item, filterValue) => {
  switch (item) {
    case "Service":
      return filterValues[item][filterValue];
    case "OriginCountry":
    case "DestinationCountry":
    case "BillingCountry":
      return countryCodes[filterValue];

    default:
      return filterValue;
  }
};

const ShipmentDelayStatusFilterValue = ({ filterValue }) => {
  const intl = useIntl();
  return !filterValue
    ? ""
    : messages[`shipmentDelayStatus_${filterValue}`]
    ? intl.formatMessage(messages[`shipmentDelayStatus_${filterValue}`])
    : filterValue;
};

export const getAppliedFilterText = (item, userSettings, module, intl) => {
  if (module === LIST_TYPE.QUOTES) {
    if (getIsDate(item, module)) {
      const fromDate = get(
        userSettings,
        "quotesFilter." + quotesColumnToFilter[item][0]
      );
      const toDate = get(
        userSettings,
        "quotesFilter." + quotesColumnToFilter[item][1]
      );
      return fromDate + " - " + toDate;
    } else {
      const filterValue = get(
        userSettings,
        "quotesFilter." + quotesColumnToFilter[item]
      );
      const filterValueRender = mapFilterValueToRender(item, filterValue);
      if (typeof filterValueRender === "string") return filterValueRender;
    }
    return item;
  } else {
    const filterValue = get(
      userSettings,
      "shipmentFilter." + columnToFilter[item]
    );
    if (item === "Status") {
      return <ShipmentDelayStatusFilterValue filterValue={filterValue} />;
    } else if (item === "Phase") {
      return shipmentPhasesLabels[filterValue];
    }
    if (typeof filterValue === "string") return filterValue;
    if (isDateRange(filterValue))
      return (
        getFormattedDate(intl)(filterValue.from) +
        " - " +
        getFormattedDate(intl)(filterValue.to)
      );
    return item;
  }
};
