import { ERROR_CODES, LIST_TYPE } from "constants/common";
import { dispatchInteractionEvent } from "adobe-analytics/content-interaction-events/ContentInteractionEvents.helpers";
import { ErrorsInteractions } from "adobe-analytics/content-interaction-events/errors/ErrorsEvents.constants";
import { messages } from "./messages";

export const getTextByViewAndError = (
  view,
  errors,
  history,
  intl,
  errorCode
) => {
  let text,
    contactUsText,
    buttonUrl,
    buttonText,
    title,
    backButtonTrackCode,
    analyticsEvent;

  const isServerError =
    !!errors.filter((error) => error.error.code === ERROR_CODES.SERVER_ERROR)
      .length || [500, 501, 503, 504].includes(errorCode);

  const isNotFound =
    !!errors.filter((error) => error.error.code === ERROR_CODES.NOT_FOUND)
      .length || [400].includes(errorCode);

  switch (view) {
    case LIST_TYPE.CDZ:
      title = isNotFound
        ? intl.formatMessage(messages.oops)
        : intl.formatMessage(messages.sorry);
      backButtonTrackCode = isNotFound ? 63 : 65;
      text = isNotFound
        ? intl.formatMessage(messages.shipmentNotFound)
        : isServerError
        ? intl.formatMessage(messages.shipmentCouldNotBeLoaded)
        : intl.formatMessage(messages.notAllowedToViewShipment);
      contactUsText = isNotFound
        ? intl.formatMessage(messages.shipmentDoesNotExist)
        : isServerError
        ? intl.formatMessage(messages.serverError)
        : intl.formatMessage(messages.shipmentOtherAccount);
      buttonUrl = "/shipments";
      buttonText = intl.formatMessage(messages.shipmentListButtonText);
      analyticsEvent = () =>
        dispatchInteractionEvent(ErrorsInteractions.BACK_TO_SHIPMENT_LIST);
      break;
    case LIST_TYPE.QUOTES:
      break;
    case LIST_TYPE.NO_USER_ROLES_ASSIGNED:
      title = "Sorry!";
      text = intl.formatMessage(messages.noAccess);
      contactUsText = "";
      break;
    default:
      title = intl.formatMessage(messages.sorry);
      text = intl.formatMessage(messages.notAllowedSection);
      contactUsText = intl.formatMessage(messages.contactToEnable);
      buttonUrl = "/";
      buttonText = intl.formatMessage(messages.buttonTextDashboard);
      analyticsEvent = () =>
        dispatchInteractionEvent(ErrorsInteractions.BACK_TO_DASHBOARD);
  }

  buttonText = buttonText
    ? isServerError
      ? intl.formatMessage(messages.buttonTextRetry)
      : intl.formatMessage(messages.buttonTextBackToItem, { item: buttonText })
    : undefined;

  if (buttonText && isServerError) {
    analyticsEvent = () => dispatchInteractionEvent(ErrorsInteractions.RETRY);
  }

  const handleClick = () => {
    analyticsEvent?.();
    if (isServerError) {
      window.location.reload(false);
    } else {
      history.push(buttonUrl);
    }
  };

  return {
    text,
    contactUsText,
    buttonText,
    title,
    handleClick,
    backButtonTrackCode,
  };
};
