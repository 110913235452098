import actionTypes from "./action-types";
import { MapSelectAdditionalOptions } from "./constants";

const initialState = {
  map: {
    clickedMarker: null,
    sidebarOpen: true,
    mapTransportMode: MapSelectAdditionalOptions.ALL,
  },
  numberLinesList: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_NUMBER_ELEMENTS_LIST:
      return {
        ...state,
        numberLinesList: action.payload,
      };
    case actionTypes.SET_ACTIVE_MARKER:
      return {
        ...state,
        map: {
          ...state.map,
          activeMarker: action.payload,
        },
      };
    case actionTypes.SET_MAP_TRANSPORT_MODE:
      return {
        ...state,
        map: {
          ...state.map,
          mapTransportMode: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
