import { shipmentDelayStatuses } from "data/shipmentDetailTypes";
import { defineMessages } from "react-intl";

export const messages = defineMessages({
  completed: {
    id: "shipment.detail.completed",
    defaultMessage: "Completed",
  },
  actualArrivalText: {
    id: "shipment.detail.actualArrivalText",
    defaultMessage: "Actual Arrival at Port / Terminal of Destination",
  },
  estimatedArrivalText: {
    id: "shipment.detail.estimatedArrivalText",
    defaultMessage: "Estimated Arrival at Port / Terminal of Destination",
  },
  print: {
    id: "shipment.detail.print",
    defaultMessage: "Print",
  },
  share: {
    id: "shipment.detail.share",
    defaultMessage: "Share",
  },
  houseBillLabel: {
    id: "shipment.detail.houseBillLabel",
    defaultMessage: "House Bill:",
  },
  route: {
    id: "shipment.detail.tab.Route",
    defaultMessage: "Route",
  },
  details: {
    id: "shipment.detail.tab.Details",
    defaultMessage: "Details",
  },
  documents: {
    id: "shipment.detail.tab.Documents",
    defaultMessage: "Documents",
  },
  [`shipmentDelayStatus_${shipmentDelayStatuses.DELAYED}`]: {
    id: "shipment.detail.delayStatus.delayed",
    defaultMessage: "Delayed",
  },
  [`shipmentDelayStatus_${shipmentDelayStatuses.ON_TIME}`]: {
    id: "shipment.detail.delayStatus.onTime",
    defaultMessage: "On Time",
  },
  serviceIncidentDescriptionButtonSeeMore: {
    id: "shipment.detail.serviceIncidence.button.seeMore",
    defaultMessage: "See more",
  },
  serviceIncidentDescriptionButtonViewLess: {
    id: "shipment.detail.serviceIncidence.button.viewLess",
    defaultMessage: "View less",
  },
  orderCodeLabel: {
    id: "shipment.detail.orderCodeLabel",
    defaultMessage: "Order Code:",
  },
  containerLabel: {
    id: "shipment.detail.containerLabel",
    defaultMessage: "Container:",
  },
  packageIdLabel: {
    id: "shipment.detail.packageIdLabel",
    defaultMessage: "Package ID:",
  },
  planned: {
    id: "shipment.detail.event.planned",
    defaultMessage: "Planned",
  },
  co2WtWEfficiencyUnit: {
    id: "shipment.detail.co2WtWEfficiencyUnit",
    defaultMessage: "g CO₂e per TKM",
  },
  co2WtWFootprintUnit: {
    id: "shipment.detail.co2WtWFootprintUnit",
    defaultMessage: "t CO₂e",
  },
});
