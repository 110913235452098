import globalDateMessages from "constants/dateMessages";
import moment from "moment-timezone";

export const getFormattedDate = (intl) => (date, withTime, longYear) => {
  if (date) {
    const convertedDate = date.replace("Z[UTC]", "+00:00");
    if (withTime) {
      return moment
        .parseZone(convertedDate)
        .format(intl.formatMessage(globalDateMessages.longDatetime));
    }
    return longYear
      ? moment
          .parseZone(convertedDate)
          .format(intl.formatMessage(globalDateMessages.longDate))
      : moment
          .parseZone(convertedDate)
          .format(intl.formatMessage(globalDateMessages.shortDate));
  } else {
    return "--";
  }
};
