import getConfigClient from "constants/getConfigClient";
import axios from "axios";
import GET_USER_ROLES from "queries/AAA/getUserRoles";
import { call, put, select } from "redux-saga/effects";
import { resetAllUserSettings } from "redux/userSettings/actions";
import {
  removeCookie,
  transformRequest,
  getKeycloakLogoutUrl,
} from "utils/auth";
import { errorsLoggingEndpoint } from "utils/ClientLogger/ClientLogger.constants";
import get, { getIt } from "utils/get";
import { constructRefreshTokenCall, processToken } from "utils/interceptor";

import client from "../../apolloClient";
import {
  getTokenError,
  getTokenSuccess,
  logOut as logoutAction,
  setCurrentUserRoles,
  setDemoUserRoles,
} from "./actions";

export function* getToken({ payload }) {
  try {
    const [data, options] = getDataAndOptionsForTokenRequest(payload);
    const response = yield call(
      axios.post,
      getConfigClient("KEYCLOAK_URL") + "token",
      data,
      options
    );
    if (response?.data?.access_token) {
      yield processTokenResponse(response);
    } else {
      axios
        .post(errorsLoggingEndpoint, {
          level: "error",
          message: "error getting access_token",
          context_response_data: response,
        })
        .finally(() => {
          window.location.href = getIt(
            window.location.origin + "/login?failed=token"
          );
        });
    }
  } catch (error) {
    axios
      .post(errorsLoggingEndpoint, {
        level: "error",
        message: "exception getting access_token",
        context_error_details: error,
      })
      .finally(() => {
        window.location.href = getIt(
          window.location.origin + "/login?failed=token"
        );
      });
  }
}

export function* refreshToken({ payload }) {
  try {
    const refreshToken = localStorage.getItem("refresh_token");
    const doRefreshToken = constructRefreshTokenCall();
    const response = yield doRefreshToken(refreshToken);
    if (response?.data?.access_token) {
      yield processTokenResponse(response);
    } else {
      if (window.location.pathname !== "/login") {
        yield put(getTokenError());
      }
    }
  } catch (error) {
    if (window.location.pathname !== "/login") {
      yield put(getTokenError(error));
    }
  }
}

export function* tokenErrorEffect() {
  yield put(logoutAction());
}

export function clearSelectedLocalStorageEntries() {
  localStorage.removeItem("MyDHLiSettingsV21");
  localStorage.removeItem("MyDHLiSettingsV22");
  localStorage.removeItem("MyDHLiSettingsV23");
  localStorage.removeItem("id_token");
  localStorage.removeItem("id_token_expiry");
  localStorage.removeItem("access_token");
  localStorage.removeItem("access_token_expiry");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("refresh_token_expiry");
  localStorage.removeItem("firstLoadCompleted");
  localStorage.removeItem("user_roles");
  sessionStorage.clear();
}

export function* logOut({ shouldSkipRedirect }) {
  const tokenId = yield localStorage.getItem("id_token");

  if (!window?.alreadyClearedCookies) {
    window.alreadyClearedCookies = true;
    yield removeCookie("refresh_token_expiry");
    yield removeCookie("refresh_token");
    yield removeCookie("access_token");
    yield removeCookie("access_token_expiry");
    yield localStorage.removeItem("returnUri");
    yield removeCookie("user_roles");
    yield removeCookie("iat_local_time_diff_in_seconds");
    yield client.clearStore();
    yield put(resetAllUserSettings());
    yield clearSelectedLocalStorageEntries();

    if (!shouldSkipRedirect) {
      window.location = getKeycloakLogoutUrl(tokenId);
    }
  }
}

export function getDataAndOptionsForTokenRequest(
  payload,
  refreshToken = undefined
) {
  const data = transformRequest({
    grant_type: refreshToken ? "refresh_token" : "authorization_code",
    client_id: getConfigClient("KEYCLOAK_CLIENT_ID"),
    code: refreshToken ? undefined : payload.code,
    refresh_token: refreshToken || undefined,
    redirect_uri: refreshToken
      ? undefined
      : getConfigClient("KEYCLOAK_REDIRECT_URI") +
          (payload.returnUrl
            ? "?ret=" + encodeURIComponent(payload.returnUrl)
            : "") || "",
  });

  const options = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    timeout: 10000,
  };
  return [data, options];
}

function* processTokenResponse(response) {
  if (get(response, "data.error")) {
    yield put(getTokenError(get(response, "data")));
  } else {
    processToken(response);
    yield put(getTokenSuccess(get(response, "data")));
  }
}

export function* getCurrentUserRolesEffect() {
  try {
    const {
      isDemo,
      corporatePartnerID,
      loggedInUserRoles,
      demoUserRoles: demoUserRolesState,
    } = yield select((state) => ({
      isDemo: state.userSettings.visibilityScope?.demo,
      corporatePartnerID: state.userSettings.visibilityScope?.uuid,
      loggedInUserRoles: state.auth?.loggedInUserRoles,
      demoUserRoles: state.auth?.demoUserRoles,
    }));
    let roles;
    if (isDemo) {
      let demoUserRoles = demoUserRolesState;
      const currentTime = Date.now();
      if (
        !(
          (demoUserRoles[corporatePartnerID]?.expiry || 0) - 1000 * 60 * 2 >
          currentTime
        )
      ) {
        const data = yield client.query({
          query: GET_USER_ROLES,
          variables: {
            corporatePartnerId: corporatePartnerID,
          },
          errorPolicy: "all",
          fetchPolicy: "no-cache",
        });
        if (data?.data?.getUserRoles) {
          demoUserRoles = {
            ...demoUserRoles,
            [corporatePartnerID]: {
              roles: (data?.data?.getUserRoles || []).map((i) => i.code),
              expiry: currentTime + 1000 * 60 * 60 * 24,
            },
          };
          yield put(setDemoUserRoles(demoUserRoles));
        }
      }
      roles = demoUserRoles[corporatePartnerID]?.roles || [];
    } else {
      roles = loggedInUserRoles;
    }
    yield put(setCurrentUserRoles(roles));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
}
