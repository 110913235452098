import useOutsideClick from "hooks/useOutsideClick";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { connect } from "react-redux";
import {
  setQuotesFilter,
  setBookingsListFilter,
} from "redux/userSettings/actions";
import styled from "styled-components";
import { getRem } from "../../../utils/css";

const FilterItemWrapper = styled.div`
  width: fit-content;
`;
const FilterItemBG = styled.div`
  &:hover {
    background-color: ${(p) => p.theme.colors.black5};
  }
`;
const FilterItem = styled.div`
  display: flex;
  cursor: pointer;
  padding: ${getRem(16)} ${getRem(20)};
`;

const ListIcons = ({ children = [], ...props }) => {
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, props.onOutsideClick);

  const handleItemClick = (key) => {
    if (props.filter) {
      if (props.module === "quotes") {
        props.setQuotesFilter(props.filter, props.transform(key));
      } else if (props.module === "bookings") {
        props.setBookingsListFilter(props.filter, props.transform(key));
      }
      props.onOutsideClick();
    }
  };

  return (
    <FilterItemWrapper ref={wrapperRef}>
      {children instanceof Array ? (
        children.map((child) => (
          <FilterItemBG key={child.key}>
            <FilterItem
              key={child.key}
              onClick={() => handleItemClick(child.key)}
            >
              {child}
            </FilterItem>
          </FilterItemBG>
        ))
      ) : (
        <FilterItemBG key={children.key}>
          <FilterItem
            key={children.key}
            onClick={() => handleItemClick(children.key)}
          >
            {children}
          </FilterItem>
        </FilterItemBG>
      )}
    </FilterItemWrapper>
  );
};

ListIcons.defaultProps = {
  bucket: {},
  module: "shipments",
  transform: (str) => str,
};
ListIcons.propTypes = {
  bucket: PropTypes.object,
  module: PropTypes.string,
  transform: PropTypes.func,
};

export default connect(null, {
  setQuotesFilter,
  setBookingsListFilter,
})(ListIcons);
