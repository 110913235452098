import actionTypes from "./action-types";
import { SORT_DIRECTIONS, sortTypes } from "./constants";

export const initialState = {
  quoteSort: undefined,
  quoteSortType: undefined,
  bookingsListSort: undefined,
  bookingsListSortType: undefined,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_QUOTE_SORT: {
      let sortDirectionOrder = [];
      if (action.payload.sortDirectionOnFirstClick === SORT_DIRECTIONS.UP) {
        sortDirectionOrder = [
          sortTypes.LOWER_CASE_DESC,
          sortTypes.LOWER_CASE_ASC,
          undefined,
        ];
      } else {
        sortDirectionOrder = [
          sortTypes.LOWER_CASE_ASC,
          sortTypes.LOWER_CASE_DESC,
          undefined,
        ];
      }
      const currentSortDirectionIndex = sortDirectionOrder.indexOf(
        state.quoteSortType
      );
      return {
        ...state,
        quoteSort: action.payload.key,
        quoteSortType:
          action.payload.key !== state.quoteSort
            ? sortDirectionOrder[0]
            : currentSortDirectionIndex === sortDirectionOrder.length - 1
            ? sortDirectionOrder[0]
            : sortDirectionOrder[currentSortDirectionIndex + 1],
      };
    }
    case actionTypes.SET_BOOKINGS_LIST_SORT: {
      let sortDirectionOrder = [];
      if (action.payload.sortDirectionOnFirstClick === SORT_DIRECTIONS.UP) {
        sortDirectionOrder = [
          sortTypes.CAPITAL_DESC,
          sortTypes.CAPITAL_ASC,
          undefined,
        ];
      } else {
        sortDirectionOrder = [
          sortTypes.CAPITAL_DESC,
          sortTypes.CAPITAL_ASC,
          undefined,
        ];
      }
      const currentSortDirectionIndex = sortDirectionOrder.indexOf(
        state.bookingsListSortType
      );
      return {
        ...state,
        bookingsListSort: action.payload.key,
        bookingsListSortType:
          action.payload.key !== state.bookingsListSort
            ? sortDirectionOrder[0]
            : currentSortDirectionIndex === sortDirectionOrder.length - 1
            ? sortDirectionOrder[0]
            : sortDirectionOrder[currentSortDirectionIndex + 1],
      };
    }
    default:
      return state;
  }
};

export default reducer;
