import * as MDM from "constants/masterDataManagement";
import { useQuery } from "@apollo/client";
import BookingElementHeadingHint from "components/atoms/BookingElementHeadingHint";
import InputFieldWithValidation from "components/organisms/fieldsWithValidation/InputFieldWithValidation/InputFieldWithValidation";
import SelectFieldWithValidation from "components/organisms/fieldsWithValidation/SelectFieldWithValidation/SelectFieldWithValidation";
import {
  formUpdateOnCountryChange,
  generateFormFields,
} from "components/organisms/StreetAddress/AddressForm/AddressForm.helpers";
import { useGetDataTestId } from "containers/TestingContext/TestingContext";
import { useIsLocalityBasedCountryCheck } from "hooks/useIsLocalityBasedCountryCheck";
import useSortedCountries from "hooks/useSortedCountries";
import { GET_COUNTRIES } from "queries/AAA/masterDataManagement";
import React, { FC, useCallback } from "react";
import { Field, useField, useForm } from "react-final-form";
import { useIntl } from "react-intl";
import { SearchInput } from "react-lib";
import { FieldRow, FormField } from "../AddOrEditAddressForm.styles";
import { SearchOptionType } from "./AddressSection.constants";
import * as H from "./AddressSection.helpers";
import messages from "./AddressSection.messages";
import * as S from "./AddressSection.styles";

interface AddressSectionProps {
  isDataLoading: boolean;
  isAddressVerified: boolean;
  onAnyAddressChange: () => void;
}

export const AddressSection: FC<AddressSectionProps> = ({
  isDataLoading,
  isAddressVerified,
  onAnyAddressChange,
}) => {
  const intl = useIntl();
  const getDataTestId = useGetDataTestId();

  const {
    countryFieldName,
    addressLine1,
    addressLine2,
    postalCode,
    city,
    companyName,
    reference,
    preferredAddressInputType,
    stateProvince: state,
  } = generateFormFields(true, "");

  const form = useForm();
  const { input: { value: selectedCountry } = {} } = useField(countryFieldName);
  const {
    handleSearchInputChange,
    handleSearchSelectChange,
    selectSearchValue,
    googleSearchInputValue,
    searchOptions,
    isSearching,
    clearSearchInput,
  } = H.useGoogleSuggestionsInput(
    selectedCountry,
    addressLine1,
    postalCode,
    city,
    state,
    form
  );
  const { input: preferredAddressInputTypeField } = useField(
    preferredAddressInputType
  );

  const { data: countriesData } =
    useQuery<{ getCountries: MDM.Country[] }>(GET_COUNTRIES);

  const { sortedOptions, setCountryInputValue } = useSortedCountries(
    countriesData?.getCountries || []
  );

  const isLocalityBasedAddress = useIsLocalityBasedCountryCheck(
    selectedCountry,
    countriesData?.getCountries
  );

  const handleCountryChange = useCallback(
    (val: string) => {
      onAnyAddressChange();
      const country = countriesData?.getCountries.find(
        (country) => country.value === val
      );

      if (country) {
        clearSearchInput();
        formUpdateOnCountryChange({
          form,
          fieldPrefix: "",
          newCountry: country,
          setInputValue: () => {},
          setSelectSearchValue: () => {},
          setAddressInputTypeField: () =>
            preferredAddressInputTypeField.onChange(country.addressType),
          isForAddressBook: true,
          isLocalityBasedAddress: !!isLocalityBasedAddress,
        });
      }
    },
    [
      clearSearchInput,
      countriesData?.getCountries,
      form,
      isLocalityBasedAddress,
      onAnyAddressChange,
      preferredAddressInputTypeField,
    ]
  );

  return (
    <S.AddressSectionContainer data-testid="ab_AddOrEditAddressForm_AddressContainer">
      <S.StyledHeadline>
        {intl.formatMessage(messages.addressSectionTitle)}
      </S.StyledHeadline>

      <FormField>
        <SelectFieldWithValidation
          name={countryFieldName}
          label={intl.formatMessage(messages.countryLabel)}
          options={sortedOptions}
          onChange={handleCountryChange}
          onInputChange={setCountryInputValue}
          shouldShowErrorMessage
          isDefaultSortingDisabled
          isSearchable
          isDisabled={isDataLoading || isAddressVerified}
        />
        {!selectedCountry && (
          <BookingElementHeadingHint>
            {intl.formatMessage(messages.selectCountryFirstHint)}
          </BookingElementHeadingHint>
        )}
      </FormField>

      {!isLocalityBasedAddress && H.isGoogleAPILoaded() && (
        <FormField>
          <Field name={addressLine2}>
            {({ input }) => (
              <SearchInput
                isDisabled={
                  !selectedCountry || isAddressVerified || isDataLoading
                }
                name={input.name}
                menuIsOpen={false}
                size="md"
                width="100%"
                placeholder={intl.formatMessage(messages.searchPlaceholder)}
                onChange={(value: Partial<SearchOptionType>) => {
                  onAnyAddressChange();
                  handleSearchSelectChange(value);
                }}
                value={selectSearchValue}
                onInputChange={handleSearchInputChange}
                inputValue={googleSearchInputValue}
                options={searchOptions}
                closeMenuOnSelect
                isClearable
                isLoading={isSearching}
                selectResultTranslation={intl.formatMessage(
                  messages.selectResult
                )}
                noOptionsMessage={intl.formatMessage(messages.noResults)}
              />
            )}
          </Field>
        </FormField>
      )}
      <FormField>
        <InputFieldWithValidation
          name={companyName}
          label={intl.formatMessage(messages.companyFieldLabel)}
          isDisabled={isDataLoading || isAddressVerified || !selectedCountry}
          onChangeCallback={onAnyAddressChange}
          inputProps={{
            "data-testid": getDataTestId("addressBook_companyName"),
          }}
        />
      </FormField>

      <FormField>
        <InputFieldWithValidation
          name={addressLine1}
          label={intl.formatMessage(messages.addressLine1Label)}
          isDisabled={isDataLoading || isAddressVerified || !selectedCountry}
          onChangeCallback={onAnyAddressChange}
          inputProps={{
            "data-testid": getDataTestId("addressBook_addressLine1"),
          }}
        />
      </FormField>

      <FieldRow>
        {!isLocalityBasedAddress && (
          <FormField>
            <InputFieldWithValidation
              name={postalCode}
              label={intl.formatMessage(messages.postalCodeLabel)}
              isDisabled={
                isDataLoading || isAddressVerified || !selectedCountry
              }
              onChangeCallback={onAnyAddressChange}
              inputProps={{
                "data-testid": getDataTestId("addressBook_postalCode"),
              }}
            />
          </FormField>
        )}

        <FormField>
          <InputFieldWithValidation
            name={city}
            label={intl.formatMessage(messages.cityLabel)}
            isDisabled={isDataLoading || isAddressVerified || !selectedCountry}
            onChangeCallback={onAnyAddressChange}
            inputProps={{
              "data-testid": getDataTestId("addressBook_city"),
            }}
          />
        </FormField>
      </FieldRow>

      <FieldRow>
        <FormField>
          <InputFieldWithValidation
            name={state}
            label={intl.formatMessage(messages.stateLabel)}
            isDisabled={isDataLoading || isAddressVerified || !selectedCountry}
            onChangeCallback={onAnyAddressChange}
            inputProps={{
              "data-testid": getDataTestId("addressBook_state"),
            }}
          />
        </FormField>

        <FormField>
          <InputFieldWithValidation
            name={reference}
            label={intl.formatMessage(messages.referenceLabel)}
            isDisabled={isDataLoading || !selectedCountry}
            onChangeCallback={onAnyAddressChange}
            inputProps={{
              "data-testid": getDataTestId("addressBook_reference"),
            }}
          />
        </FormField>
      </FieldRow>
    </S.AddressSectionContainer>
  );
};
