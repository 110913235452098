import React, { ReactNode } from "react";
import Highlighter from "react-highlight-words";
import {
  Color,
  Popover as PopoverBase,
  ListForPopover as ListForPopoverBase,
  Divider,
  media,
} from "react-lib";
import styled, { css } from "styled-components";
import { getRem } from "utils/css";
import { LIST_PADDING_LEFT } from "../../TableHeader/partials/constants";
import {
  ShipmentColContainer,
  TableCol,
} from "../../TableHeader/partials/Header";
import { TableRow } from "../../TableHeader/partials/index";

export const ButtonCol = styled(ShipmentColContainer)`
  visibility: hidden;
  justify-content: space-evenly;
  padding-right: ${getRem(30)};
`;

export const ShipmentRowContainer = styled(TableRow)<{
  shouldHighlightRow?: boolean;
}>`
  min-height: ${getRem(48)};
  padding-left: ${LIST_PADDING_LEFT};
  box-shadow: inset 0 -1px 0 0 ${(themeProps) => themeProps.theme.colors.black10};
  &:hover {
    background-color: ${(themeProps) => themeProps.theme.colors.black5};
    cursor: pointer;
  }
  &:hover ${ButtonCol}, &:focus ${ButtonCol} {
    visibility: visible;
  }
  ${({ shouldHighlightRow }) =>
    shouldHighlightRow
      ? css`
          background-color: ${(themeProps) => themeProps.theme.colors.black5};
        `
      : null};
`;

export const StyledHighlighter = styled(Highlighter)<{
  searchWords?: string;
  textToHighlight?: string;
}>``;

export const ListForPopover = styled(ListForPopoverBase)`
  margin: 0.75rem 0;

  > li {
    padding: 0.75rem 1.25rem;

    > svg {
      width: 1.5rem;
      margin-right: 0.75rem !important;
    }
  }
`;

export const ListForPopoverItem = styled(ListForPopoverBase.Item)`
  color: ${({ isDisabled }) => isDisabled && Color.gray20};
  fill: ${({ isDisabled }) => isDisabled && Color.gray20};
  g {
    fill: ${({ isDisabled }) => isDisabled && Color.gray20};
  }
`;

export const DividerForPopover = styled(Divider)`
  margin: 0.25rem auto;
`;

export const Popover = styled(PopoverBase)`
  margin-right: 1rem;
  margin-top: 1.5rem;
  min-width: 10rem;

  ${media.from("sm")} {
    margin-top: 0;
  }
`;

export const OptionsWrapper = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  text-align: center;
  fill: ${Color.webBlack};

  svg {
    width: 1.5rem;
    height: 1.5rem;
    visibility: visible;
  }

  ${media.from("sm")} {
    padding: 0.875rem 0.438rem;
    margin-right: 1rem;
    height: initial;
    width: initial;
    & svg {
      visibility: hidden;
    }
  }
`;

export const StyledRowContainer = styled(ShipmentRowContainer)<{
  onClick: (event?: React.SyntheticEvent) => void;
  shouldHighlightRow: boolean;
  isDisabled: boolean | undefined;
}>`
  justify-content: space-between;
  flex-wrap: nowrap;
  margin: 0 1.375rem 0;
  padding: 0.625rem 0rem 1.5rem;
  &:hover {
    margin: 0;
    padding: 0.625rem 1.375rem 1.5rem;
    cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
    ${OptionsWrapper} svg {
      visibility: visible;
    }
  }
  span {
    &:hover {
      cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
    }
  }
  ${media.from("sm")} {
    justify-content: initial;
    flex-wrap: initial;
    padding: 0 0 0 1.25rem;
    margin: 0;

    &:hover {
      padding: 0 0 0 1.25rem;
      margin: 0;
    }
  }
`;

export const ColContainer = styled(TableCol)<{
  "data-track"?: string | number;
  key: string;
  customSize: string | number;
  addLeftMargin: boolean;
  children: ReactNode;
}>`
  display: flex;
  align-items: center;
  height: 3rem;
  &:last-child {
    justify-content: end;
  }
  ${(p) =>
    p.addLeftMargin &&
    css`
      > * {
        margin-left: 1.5rem;
      }
    `}
`;

export const OptionsColContainer = styled(ColContainer)`
  flex-direction: "row-reverse";
`;
