export const numberOfSearchHistoryItem = 50;
export enum LIST_TYPE {
  QUOTES = "quotes",
  CDZ = "cdz",
  BOOKINGS = "bookings",
  BOOKING_DRAFTS = "booking_drafts",
  NO_USER_ROLES_ASSIGNED = "no_user_roles_assigned",
}

export enum ERROR_CODES {
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  SERVER_ERROR = 500,
}
