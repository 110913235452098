import { ReactComponent as CloseIcon } from "assets/icons/myDHLi/cancel.svg";
import { ReactComponent as Exception } from "assets/icons/myDHLi/exception.svg";
import IconButton from "components/atoms/Buttons/IconButton";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { connect, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { hideAllErrors, removeAllErrors } from "redux/error/actions";
import { getRem, SCREEN_SIZES } from "utils/css";
import { isLocal } from "utils/env";
import {
  getErrorMessage,
  hasCriticalError,
  shouldShowErrorPopup,
} from "utils/error";
import { messages } from "./messages";
import {
  Container,
  SVGWrapper,
  ContentWrapper,
  Content,
  ButtonWrapper,
} from "./Styles";

const ErrorPopup = ({ errors, userSettings, onReloadButton }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const intl = useIntl();
  const pathname = location?.pathname;
  const shouldShowPopup = shouldShowErrorPopup(errors, userSettings, pathname);
  const isCritical = isLocal() ? false : hasCriticalError(errors);
  const timeOut = isLocal() ? 30000 : 7000;
  const errorCount = errors.length;
  const isMobile = useMediaQuery({ maxWidth: SCREEN_SIZES.until.md });

  useEffect(() => {
    const lastPart = pathname.split("/").pop();
    if (
      lastPart !== "documents" &&
      lastPart !== "details" &&
      !pathname.includes("booking/route") &&
      !pathname.includes("booking/cargo")
    ) {
      dispatch(removeAllErrors());
    }
  }, [pathname, dispatch]);

  useEffect(() => {
    let handle;
    if (shouldShowPopup && !isCritical) {
      handle = setTimeout(() => {
        handleOnClose();
      }, timeOut);
    }
    return () => {
      if (handle) clearTimeout(handle);
    };
    // eslint-disable-next-line
  }, [isCritical, shouldShowPopup, errorCount]);

  const handleOnClose = () => {
    dispatch(hideAllErrors());
  };

  const handleReload = () => {
    onReloadButton();
  };

  if (!shouldShowPopup) return null;
  const iconSize = isMobile ? 24 : 48;

  return (
    <Container data-testid="error-popup">
      <SVGWrapper
        width={getRem(iconSize)}
        height={getRem(iconSize)}
        fill="#d40511"
        hoverFill="#eb131e"
      >
        <Exception />
      </SVGWrapper>
      <ContentWrapper>
        <Content>{getErrorMessage(errors, isCritical, intl)}</Content>
        {isCritical && (
          <ButtonWrapper onClick={handleReload} data-track={60}>
            <IconButton
              margin
              color="#ffffff"
              hoverColor="#ffffff"
              bgColor="#d40511"
              padding={`${getRem(13)} ${getRem(32)}`}
              hideBorder
            >
              {intl.formatMessage(messages.reload)}
            </IconButton>
          </ButtonWrapper>
        )}
      </ContentWrapper>
      {!isCritical && (
        <SVGWrapper
          data-testid="error-popup-close"
          fill="white"
          hoverFill="white"
          data-track={61}
          onClick={handleOnClose}
        >
          <CloseIcon style={{ cursor: "pointer" }} />
        </SVGWrapper>
      )}
    </Container>
  );
};

ErrorPopup.defaultProps = {
  errors: [],
  onReloadButton: () => {
    window.location.reload();
  },
};
ErrorPopup.propTypes = {
  errors: PropTypes.array,
  onReloadButton: PropTypes.func,
};
export default connect(
  (state) => ({
    errors: state.error.errors,
    userSettings: state.userSettings,
  }),
  {}
)(ErrorPopup);
