import getConfigClient from "constants/getConfigClient";
import axios from "axios";
import { getActualLanguage } from "containers/Translations/Translations.helpers";
import startsWith from "lodash/startsWith";
import {
  setLoggedInUserEmail,
  setLoggedInUserRoles,
  setUserId,
  setUserLoggedIn,
} from "redux/auth/actions";
import {
  clearSelectedLocalStorageEntries,
  getDataAndOptionsForTokenRequest,
} from "redux/auth/effects";
import { store } from "redux/store";
import get from "utils/get";
import { v4 as uuidv4 } from "uuid";
import client from "../apolloClient";
import {
  getKeycloakLoginUrl,
  getPropertyFromToken,
  getTokenExpiryTimestamp,
  getTokenIatTimestamp,
  hasValidAuthToken,
  hasValidRefreshToken,
  refreshTokenIfExpiringSoon,
  removeCookie,
} from "./auth";

const ENDPOINTS_WITHOUT_AUTH = ["log-browser-data"];

export const buildRefreshTokenPromise = (refreshToken) => {
  const [data, options] = getDataAndOptionsForTokenRequest({}, refreshToken);
  return axios({
    method: "post",
    url: getConfigClient("KEYCLOAK_URL") + "token",
    data: data,
    ...options,
  });
};

// Introduce closure to keep promise and context of refresh token
// With the assumption that concurrent refresh token calls will have the same refresh token, we can avoid unnecessary refresh token calls
export const constructRefreshTokenCall = () => {
  let previousRefreshToken;
  let currentPromise;
  return (refreshToken) => {
    if (currentPromise && previousRefreshToken === refreshToken) {
      return currentPromise;
    }
    currentPromise = buildRefreshTokenPromise(refreshToken);
    previousRefreshToken = refreshToken;
    return currentPromise;
  };
};

const clearAuthCookies = () => {
  removeCookie("refresh_token_expiry");
  removeCookie("refresh_token");
  removeCookie("access_token");
  removeCookie("access_token_expiry");
  removeCookie("user_roles");
  removeCookie("iat_local_time_diff_in_seconds");
};

export const clearOut = async (forceRedirect = false) => {
  if (!window?.alreadyClearedCookies) {
    window.alreadyClearedCookies = true;
    clearAuthCookies();
    clearSelectedLocalStorageEntries();
    await client.clearStore();
    window.location = getKeycloakLoginUrl();
  }

  if (forceRedirect) {
    window.location = getKeycloakLoginUrl();
  }
};

export const clearAndRedirect = (url) => {
  if (!window?.alreadyClearedCookies) {
    window.alreadyClearedCookies = true;
    clearAuthCookies();
    localStorage.removeItem("returnUri");
    clearSelectedLocalStorageEntries();
    window.location = url;
  }
};

export const processToken = (response) => {
  const accessTokenFromResponse = get(response, "data.access_token", null);
  const refreshTokenFromResponse = get(response, "data.refresh_token", null);
  const idToken = get(response, "data.id_token", null);
  const iatInSeconds = getTokenIatTimestamp(refreshTokenFromResponse);

  if (iatInSeconds) {
    const iatInMilliseconds = iatInSeconds * 1000;
    const currentTimeInMilliseconds = new Date().valueOf();
    const timeDiffInMilliseconds =
      currentTimeInMilliseconds - iatInMilliseconds;

    localStorage.setItem(
      "iat_local_time_diff_in_seconds",
      Math.floor(timeDiffInMilliseconds / 1000).toString()
    );
  }

  if (accessTokenFromResponse) {
    localStorage.setItem("access_token", accessTokenFromResponse);
    localStorage.setItem(
      "access_token_expiry",
      getTokenExpiryTimestamp(accessTokenFromResponse)
    );
    store.dispatch(
      setLoggedInUserRoles(
        getPropertyFromToken(accessTokenFromResponse, "realm_access.roles")
      )
    );
    store.dispatch(
      setLoggedInUserEmail(
        getPropertyFromToken(accessTokenFromResponse, "email")
      )
    );
    store.dispatch(
      setUserId(getPropertyFromToken(accessTokenFromResponse, "AAA_ID"))
    );
    store.dispatch(setUserLoggedIn(true));
  }

  if (refreshTokenFromResponse) {
    localStorage.setItem("refresh_token", refreshTokenFromResponse);
    localStorage.setItem(
      "refresh_token_expiry",
      getTokenExpiryTimestamp(refreshTokenFromResponse)
    );
  }

  if (idToken) {
    localStorage.setItem("id_token", idToken);
    localStorage.setItem("id_token_expiry", getTokenExpiryTimestamp(idToken));
  }

  return accessTokenFromResponse;
};

export const refreshToken = async () => {
  const refreshToken = localStorage.getItem("refresh_token");
  const doRefreshToken = constructRefreshTokenCall();
  try {
    const response = await doRefreshToken(refreshToken);
    if (response?.data?.access_token) {
      return processToken(response);
    } else {
      if (window.location.pathname !== "/login") {
        localStorage.setItem(
          "returnUri",
          window.location.pathname + window.location.search
        );
        clearOut();
      }
    }
  } catch (e) {
    if (window.location.pathname !== "/login") {
      localStorage.setItem(
        "returnUri",
        window.location.pathname + window.location.search
      );
      clearOut();
    }
  }
};

export async function requestInterceptor(config) {
  const isNotKeycloakUrl = !startsWith(
    config.url,
    getConfigClient("KEYCLOAK_URL")
  );
  const isNotGoogle = !startsWith(config.url, "https://maps.googleapis.com");
  const isEndpointWithoutAuth = ENDPOINTS_WITHOUT_AUTH.some(
    (path) => config.url === getConfigClient("NODE_ENDPOINT") + path
  );
  if (isNotKeycloakUrl && isNotGoogle) {
    config.headers = {
      ...config.headers,
      x_request_id: uuidv4(),
      X_LOCALE_ID: getActualLanguage() || "en",
    };
  }
  if (isNotKeycloakUrl && !(config.skipAuth || isEndpointWithoutAuth)) {
    if (!hasValidAuthToken()) {
      if (hasValidRefreshToken()) {
        await refreshToken();
      } else {
        clearOut();
      }
    } else {
      refreshTokenIfExpiringSoon();
    }
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: "Bearer " + token,
      };
    }
  }

  return config;
}

export function responseSuccessInterceptor(response) {
  return response;
}

export async function responseErrorInterceptor(error) {
  const originalRequest = error.config;
  if (!startsWith(originalRequest.url, getConfigClient("KEYCLOAK_URL"))) {
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      if (hasValidRefreshToken()) {
        await refreshToken();
        axios.defaults.headers.common.Authorization =
          "Bearer " + localStorage.getItem("access_token");
        return axios(originalRequest);
      } else {
        await clearOut();
      }
    }
  }
  return error.response;
}
