import { ShipmentMode, ShipmentsByCurrentPosition } from "constants/shipments";
import { Marker } from "components/MapProvider/MapProvider.constants";

export type DashboardMapMarker = ShipmentsByCurrentPosition & Marker;

export enum MapSelectAdditionalOptions {
  ALL = "ALL",
}

export type ShipmentsByModeMapSelectOptions =
  | MapSelectAdditionalOptions
  | ShipmentMode;
