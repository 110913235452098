export const columnToFilter = {
  Mode: "transportMode",
  Origin: "origin",
  Destination: "destination",
  Phase: "transitStatus",
  Status: "delayStatus",
  LastEvent: "lastEvent",
  Shipper: "shipper",
  Consignee: "consignee",
  Customer: "customerName",
  OriginCountry: "originCountryName",
  OriginPort: "originPort",
  DestinationCountry: "destinationCountryName",
  DestinationPort: "destinationPort",
  Follow: "isFavorite",
  Departure: "departureDate",
  Arrival: "arrivalDate",
  HouseBill: "housebill",
  BookingName: "bookingName",
  Carrier: "carrier",
  Reference: "references",
  CustomsEntryNumber: "customsEntryNumber",
  EstimatedPickup: "estimatedPickup",
  ActualPickup: "actualPickup",
  EstimatedDeparturePort: "estimatedDeparture",
  ActualDeparturePort: "actualDeparture",
  EstimatedArrivalPort: "estimatedArrival",
  ActualArrivalPort: "actualArrival",
  EstimatedCustomerDelivery: "estimatedCustomerDelivery",
  DocumentHandover: "documentHandover",
  JourneyDuration: undefined,
  DelayInShipmentArrival: undefined,
  MasterBill: "masterbill",
  FlightNumber: "flightNumber",
  VesselName: "vessel",
  ContainerNumber: "containerNumber",
  ContainerSize: "containerSize",
  GoodsDescription: "goodsDescription",
  Volume: undefined,
  TotalWeight: undefined,
  ChargeableWeight: undefined,
  Pieces: undefined,
  IncotermsDescription: "incoTermsName",
  IncotermsCode: "incoTerms",
  FeatureNames: "featureName",
  ProductName: "productName",
  Irregularities: "irregularities",
};

export const dateTableColumnSize = 6;
