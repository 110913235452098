import { TopBarHeight, TopBarHeightMd } from "constants/variables";
import { colors } from "react-lib";
import { SCREEN_SIZES } from "react-lib/es/utils/css";
import styled from "styled-components";
import Container from "./Container";

const TopBar = styled(Container).attrs({ fluid: true })`
  background-image: linear-gradient(
    to right,
    ${colors.postYellow} 0%,
    ${colors.postYellow} 30%,
    #ffe57f 79%,
    #fff0b2 100%
  );
  height: ${TopBarHeight};
  line-height: ${TopBarHeight};
  position: absolute;
  z-index: 120;
  display: flex;
  top: 0;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);

  @media (min-width: ${SCREEN_SIZES.from.md}) {
    top: 0;
    height: ${TopBarHeightMd};
    line-height: ${TopBarHeightMd};
  }
}
`;

export default TopBar;
