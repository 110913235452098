import React, { forwardRef } from "react";

const MoreIcon = forwardRef(({ horizontal }, ref) => {
  return (
    <svg viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        transform={!horizontal && "rotate(-90 12 12)"}
        d="M5 14a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm7 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm7 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
      />
    </svg>
  );
});

export default MoreIcon;
