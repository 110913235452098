import MoreIcon from "components/atoms/Icons/MoreIcon";
import styled, { css } from "styled-components";
import { getRem } from "../../../../utils/css";
import { LIST_PADDING_LEFT } from "./constants";

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Wrapper = styled(TableRow)`
  min-height: ${getRem(48)};
  border-top: solid ${getRem(1)} transparent;
  border-bottom: solid ${getRem(1)} ${(props) => props.theme.colors.black};
  position: sticky;
  top: 0;
  background-color: ${(props) => props.theme.colors.white};
  padding-left: ${LIST_PADDING_LEFT};
  padding-top: ${getRem(14)};
  padding-bottom: ${getRem(14)};
  ${(p) =>
    p.disabled
      ? css`
          opacity: 0.45;
          border-bottom: none;
          pointer-events: none;
        `
      : null};

  ${(p) =>
    p.hideTotal
      ? css`
          z-index: 2;
        `
      : null};
`;

export const MoreButton = styled(MoreIcon).attrs((props) => ({
  color: props.theme.colors.black,
}))``;
