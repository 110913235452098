import SERVICES from "constants/services";
import { useMemo } from "react";
import getActiveService from "utils/getActiveService";
import { startsWithAnyOf } from "utils/string";

const getHideNav = (path, isQuoteRoute) => {
  return (
    isQuoteRoute ||
    startsWithAnyOf(path, [
      "/reporting/preview",
      "/reporting/edit",
      "/reporting/unsubscribe",
      "/download-report-archive/report-file",
      "/notifications/digest",
      "/settings",
      "/make-a-booking",
      "/email-link/",
      "/subscriptions/",
      "/shipments/print/",
    ])
  );
};

const getShouldNotRenderNav = (path, isQuoteRoute) => {
  return (
    isQuoteRoute ||
    startsWithAnyOf(path, [
      "/login",
      "/logout",
      "/reporting/preview",
      "/reporting/edit",
      "/reporting/unsubscribe",
      "/email-link/",
      "/orders/",
      "/order-lines/",
      "/shipments/",
      "/support",
    ]) ||
    window.NoValidTokenExists
  );
};

const getValidPage = (path, isQuoteRoute) => {
  return (
    path === "/" ||
    isQuoteRoute ||
    startsWithAnyOf(path, [
      "/shipments",
      "/cdz",
      "/quotes",
      "/analytics",
      "/login",
      "/logout",
      "/error",
      "/reporting",
      "/reporting/preview",
      "/reporting/edit",
      "/reporting/unsubscribe",
      "/settings",
      "/email-link/",
      "/bookings",
      "/booking-drafts",
      "/orders",
      "/order-lines",
      "/support",
    ])
  );
};

export const useNavHelper = (pathArg) => {
  const path = (pathArg || "").toLowerCase();
  return useMemo(() => {
    const activeService = getActiveService(path);
    const isQuoteRoute = activeService === SERVICES.OQT;
    sessionStorage.setItem("track_isQuoteRoute", isQuoteRoute);

    return {
      hideNav: getHideNav(path, isQuoteRoute),
      validPage: getValidPage(path, isQuoteRoute),
      shouldNotRenderNav: getShouldNotRenderNav(path, isQuoteRoute),
      isQuoteRoute,
    };
  }, [path]);
};
