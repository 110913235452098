import { all, fork, takeLatest } from "redux-saga/effects";
import types from "./action-types";
import {
  getCorporatePartnersEffect,
  loadColumnPreferences,
  loadCorporatePartnerPreference,
  saveCorporatePartnerPreference,
  saveColumnOrder,
} from "./effects";

function* watchGetCorporatePartners() {
  yield takeLatest(types.GET_CORPORATE_PARTNERS, getCorporatePartnersEffect);
}

function* watchLoadColumnPreferences() {
  yield takeLatest(types.LOAD_COLUMN_PREFERENCES, loadColumnPreferences);
}

function* watchSaveTableColumnOrder() {
  yield takeLatest(
    [
      types.CHANGE_QUOTES_COLUMN_ORDER,
      types.RESET_QUOTES_COLUMN_ORDER,
      types.CHANGE_BOOKINGS_COLUMN_ORDER,
      types.RESET_BOOKINGS_COLUMN_ORDER,
      types.CHANGE_DRAFT_BOOKINGS_COLUMN_ORDER,
      types.RESET_DRAFT_BOOKINGS_COLUMN_ORDER,
    ],
    saveColumnOrder
  );
}

function* watchSaveCorporatePartnerPreference() {
  yield takeLatest(types.SAVE_VISIBILITY_SCOPE, saveCorporatePartnerPreference);
}

function* watchLoadCorporatePartnerPreference() {
  yield takeLatest(
    types.LOAD_CORPORATE_PARTNER_PREFERENCE,
    loadCorporatePartnerPreference
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchSaveTableColumnOrder),
    fork(watchGetCorporatePartners),
    fork(watchLoadColumnPreferences),
    fork(watchSaveCorporatePartnerPreference),
    fork(watchLoadCorporatePartnerPreference),
  ]);
}
