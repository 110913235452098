import { defineMessages } from "react-intl";

export const messages = defineMessages({
  selectAll: {
    id: "cdz.export.selectAll",
    defaultMessage: "Select All",
  },
  unselectAll: {
    id: "cdz.export.unselectAll",
    defaultMessage: "Unselect All",
  },
  cancelExport: {
    id: "cdz.export.cancelExport",
    defaultMessage: "Cancel",
  },
  doExport: {
    id: "cdz.export.doExport",
    defaultMessage: "Export",
  },
  Mode: {
    id: "cdz.export.Mode",
    defaultMessage: "Mode",
  },
  Reference: {
    id: "cdz.export.CustomerReference",
    defaultMessage: "Customer Reference",
  },
  OriginCityCountry: {
    id: "cdz.export.OriginCityCountry",
    defaultMessage: "Origin",
  },
  DestinationCityCountry: {
    id: "cdz.export.DestinationCityCountry",
    defaultMessage: "Destination",
  },
  QuoteTotal: {
    id: "cdz.export.QuoteTotal",
    defaultMessage: "cdz Total",
  },
  Status: {
    id: "cdz.export.QuoteStatus",
    defaultMessage: "Status",
  },
  HouseBill: {
    id: "cdz.export.Housebills",
    defaultMessage: "Housebills",
  },
  Weight: {
    id: "cdz.export.Weight",
    defaultMessage: "Weight",
  },
  Volume: {
    id: "cdz.export.Volume",
    defaultMessage: "Volume",
  },
  ExpirationDate: {
    id: "cdz.export.ExpirationDate",
    defaultMessage: "Expiration Date",
  },
  OriginCountry: {
    id: "cdz.export.OriginCountry",
    defaultMessage: "Origin Country/Region",
  },
  DestinationCountry: {
    id: "cdz.export.DestinationCountry",
    defaultMessage: "Destination Country/Region",
  },
  Origin: {
    id: "cdz.export.Origin",
    defaultMessage: "Origin Code",
  },
  Destination: {
    id: "cdz.export.Destination",
    defaultMessage: "Destination Code",
  },
  BillingCountry: {
    id: "cdz.export.BillingCountry",
    defaultMessage: "Billing Country/Region",
  },
  Company: {
    id: "cdz.export.Company",
    defaultMessage: "Company",
  },
  CreatedBy: {
    id: "cdz.export.CreatedBy",
    defaultMessage: "Created By",
  },
  RateType: {
    id: "cdz.export.RateType",
    defaultMessage: "Rate Type",
  },
  ServiceCharge: {
    id: "cdz.export.ServiceCharge",
    defaultMessage: "Service Charge",
  },
  ExportCustomsClearance: {
    id: "cdz.export.ExportCustomsClearance",
    defaultMessage: "Export Customs Charge",
  },
  Shipper: {
    id: "cdz.export.Shipper",
    defaultMessage: "Shipper",
  },
  Consignee: {
    id: "cdz.export.Consignee",
    defaultMessage: "Consignee",
  },
  PortOfUnloading: {
    id: "cdz.export.PortOfUnloading",
    defaultMessage: "Port Of Unloading",
  },
  StatusDateTime: {
    id: "cdz.export.StatusDateTime",
    defaultMessage: "Status Date/Time",
  },
  TotalPackages: {
    id: "cdz.export.TotalPackages",
    defaultMessage: "Total Packages",
  },
  TotalWeight: {
    id: "cdz.export.TotalWeight",
    defaultMessage: "Total Weight",
  },
  TotalChargeableWeight: {
    id: "cdz.export.TotalChargeableWeight",
    defaultMessage: "Total Chargeable Weight",
  },
  TotalVolume: {
    id: "cdz.export.TotalVolume",
    defaultMessage: "Total Volume",
  },
  ProductType: {
    id: "cdz.export.ProductType",
    defaultMessage: "Product Type",
  },
  TransportMode: {
    id: "cdz.export.TransportMode",
    defaultMessage: "Transport Mode",
  },
  GoodsDescription: {
    id: "cdz.export.GoodsDescription",
    defaultMessage: "Goods Description",
  },
  CreationDate: {
    id: "cdz.export.CreationDate",
    defaultMessage: "Creation Date",
  },
  PortOfLoading: {
    id: "cdz.export.PortOfLoading",
    defaultMessage: "Port Of Loading",
  },
  PlaceOfLoading: {
    id: "cdz.export.PlaceOfLoading",
    defaultMessage: "Place Of Loading",
  },
  CountryOfLoading: {
    id: "cdz.export.CountryOfLoading",
    defaultMessage: "Country Of Loading",
  },
  PlaceOfUnloading: {
    id: "cdz.export.PlaceOfUnloading",
    defaultMessage: "Place Of Unloading",
  },
  CountryOfUnloading: {
    id: "cdz.export.CountryOfUnloading",
    defaultMessage: "Country Of Unloading",
  },
  CustomsClearanceNumber: {
    id: "cdz.export.CustomsClearanceNumber",
    defaultMessage: "Customs Clearance",
  },
  CustomsEntry: {
    id: "cdz.export.CustomsEntry",
    defaultMessage: "Customs Entry",
  },
  DeclarationType: {
    id: "cdz.export.DeclarationType",
    defaultMessage: "Declaration Type",
  },
  ResponsibleCustomsOffice: {
    id: "cdz.export.ResponsibleCustomsOffice",
    defaultMessage: "Responsible Customs Office",
  },
  ClearanceDate: {
    id: "cdz.export.ClearanceDate",
    defaultMessage: "Clearance Date",
  },
  Forwarder: {
    id: "cdz.export.Forwarder",
    defaultMessage: "Forwarder",
  },
  MasterBill: {
    id: "cdz.export.MasterBill",
    defaultMessage: "MasterBill",
  },
  paginationInfo: {
    id: "cdz.pagination.info",
    defaultMessage: "{declarations} of {total} declarations",
  },
  loadMoreButton: {
    id: "cdz.button.loadMore",
    defaultMessage: "Load More",
  },
  allLoaded: {
    id: "cdz.allLoaded",
    defaultMessage:
      "{total, plural, one {# Declaration} other {# Declarations}} loaded.",
  },
  cdzExportTitle: {
    id: "cdz.exportTitle",
    defaultMessage: "Export Declarations",
  },
  cdzExportDescription: {
    id: "cdz.exportDescription",
    defaultMessage: "Currently only the {part1}",
  },
  cdzExportDescriptionPart1: {
    id: "cdz.exportDescription.boldPart1",
    defaultMessage: "first 2,000 declarations can be exported.",
  },
});

export const shipmentsMessages = defineMessages({
  shipmentCancelExport: {
    id: "shipment.cancelExport",
    defaultMessage: "Cancel",
  },
  shipmentDoExport: {
    id: "shipment.doExport",
    defaultMessage: "Export",
  },
  shipmentExportTitle: {
    id: "shipment.exportTitle",
    defaultMessage: "Export Shipments",
  },
  shipmentSelectAll: {
    id: "shipment.selectAll",
    defaultMessage: "Select All",
  },
  shipmentUnselectAll: {
    id: "shipment.unselectAll",
    defaultMessage: "Unselect All",
  },
  shipmentExportDescription: {
    id: "shipment.exportDescription",
    defaultMessage:
      "Currently only the {part1} If there is more than one container per shipment, {part2}",
  },
  shipmentExportDescriptionPart1: {
    id: "shipment.exportDescription.boldPart1",
    defaultMessage: "first 2,000 shipments can be exported.",
  },
  shipmentExportDescriptionPart2: {
    id: "shipment.exportDescription.boldPart2",
    defaultMessage: "containers are added as individual rows.",
  },
  shipmentFollow: {
    id: "shipment.column.shipmentFollow",
    defaultMessage: "Follow",
  },
  shipmentMode: {
    id: "shipment.column.shipmentMode",
    defaultMessage: "Mode",
  },
  shipmentDeparture: {
    id: "shipment.column.shipmentDeparture",
    defaultMessage: "Transit Start",
  },
  shipmentOrigin: {
    id: "shipment.column.shipmentOrigin",
    defaultMessage: "Origin",
  },
  shipmentArrival: {
    id: "shipment.column.shipmentArrival",
    defaultMessage: "Transit End",
  },
  shipmentDestination: {
    id: "shipment.column.shipmentDestination",
    defaultMessage: "Destination",
  },
  shipmentHouseBill: {
    id: "shipment.column.shipmentHouseBill",
    defaultMessage: "House Bill",
  },
  shipmentPhase: {
    id: "shipment.column.shipmentPhase",
    defaultMessage: "Phase",
  },
  shipmentStatus: {
    id: "shipment.column.shipmentStatus",
    defaultMessage: "Status",
  },
  shipmentLastEvent: {
    id: "shipment.column.shipmentLastEvent",
    defaultMessage: "Last Event",
  },
  shipmentCarrier: {
    id: "shipment.column.shipmentCarrier",
    defaultMessage: "Carrier",
  },
  shipmentShipper: {
    id: "shipment.column.shipmentShipper",
    defaultMessage: "Shipper",
  },
  shipmentConsignee: {
    id: "shipment.column.shipmentConsignee",
    defaultMessage: "Consignee",
  },
  shipmentCustomer: {
    id: "shipment.column.shipmentCustomer",
    defaultMessage: "Customer",
  },
  shipmentReference: {
    id: "shipment.column.shipmentReference",
    defaultMessage: "Your Reference",
  },
  shipmentCustomsEntryNumber: {
    id: "shipment.column.shipmentCustomsEntryNumber",
    defaultMessage: "Customs entry",
  },
  shipmentOriginCountry: {
    id: "shipment.column.shipmentOriginCountry",
    defaultMessage: "Origin Country/Region",
  },
  shipmentOriginPort: {
    id: "shipment.column.shipmentOriginPort",
    defaultMessage: "Origin Port",
  },
  shipmentDestinationCountry: {
    id: "shipment.column.shipmentDestinationCountry",
    defaultMessage: "Destination Country/Region",
  },
  shipmentDestinationPort: {
    id: "shipment.column.shipmentDestinationPort",
    defaultMessage: "Destination Port",
  },
  shipmentPlannedPickup: {
    id: "shipment.column.shipmentPlannedPickup",
    defaultMessage: "Planned Pickup",
  },
  shipmentEstimatedPickup: {
    id: "shipment.column.shipmentEstimatedPickup",
    defaultMessage: "Estimated Pickup",
  },
  shipmentActualPickup: {
    id: "shipment.column.shipmentActualPickup",
    defaultMessage: "Actual Pickup/Received at Warehouse",
  },
  shipmentEstimatedDeparturePort: {
    id: "shipment.column.shipmentEstimatedDeparturePort",
    defaultMessage: "Estimated Departure from Port/Terminal",
  },
  shipmentActualDeparturePort: {
    id: "shipment.column.shipmentActualDeparturePort",
    defaultMessage: "Actual Departure from Port/Terminal",
  },
  shipmentEstimatedArrivalPort: {
    id: "shipment.column.shipmentEstimatedArrivalPort",
    defaultMessage: "Estimated Arrival at Port/Terminal",
  },
  shipmentActualArrivalPort: {
    id: "shipment.column.shipmentActualArrivalPort",
    defaultMessage: "Actual Arrival at Port/Terminal",
  },
  shipmentEstimatedCustomerDelivery: {
    id: "shipment.column.shipmentEstimatedCustomerDelivery",
    defaultMessage: "Estimated Customer Delivery",
  },
  shipmentDocumentHandover: {
    id: "shipment.column.shipmentDocumentHandover",
    defaultMessage: "Actual Customer Delivery",
  },
  shipmentJourneyDuration: {
    id: "shipment.column.shipmentJourneyDuration",
    defaultMessage: "Journey Duration",
  },
  shipmentDelayInShipmentArrival: {
    id: "shipment.column.shipmentDelayInShipmentArrival",
    defaultMessage: "Delay in shipment arrival (days)",
  },
  shipmentDelayInCustomerDelivery: {
    id: "shipment.column.shipmentDelayInCustomerDelivery",
    defaultMessage: "Delay in customer delivery (days)",
  },
  shipmentMasterBill: {
    id: "shipment.column.shipmentMasterBill",
    defaultMessage: "Master Bill",
  },
  shipmentFlightNumber: {
    id: "shipment.column.shipmentFlightNumber",
    defaultMessage: "Flight/Voyage",
  },
  shipmentVesselName: {
    id: "shipment.column.shipmentVesselName",
    defaultMessage: "Vessel",
  },
  shipmentContainerNumber: {
    id: "shipment.column.shipmentContainerNumber",
    defaultMessage: "Container",
  },
  shipmentContainerSize: {
    id: "shipment.column.shipmentContainerSize",
    defaultMessage: "Container Size",
  },
  shipmentGoodsDescription: {
    id: "shipment.column.shipmentGoodsDescription",
    defaultMessage: "Goods Description",
  },
  shipmentVolume: {
    id: "shipment.column.shipmentVolume",
    defaultMessage: "Volume",
  },
  shipmentTotalWeight: {
    id: "shipment.column.shipmentTotalWeight",
    defaultMessage: "Total Weight",
  },
  shipmentChargeableWeight: {
    id: "shipment.column.shipmentChargeableWeight",
    defaultMessage: "Chargeable Weight",
  },
  shipmentPieces: {
    id: "shipment.column.shipmentPieces",
    defaultMessage: "Pieces",
  },
  shipmentIncotermsDescription: {
    id: "shipment.column.shipmentIncotermsDescription",
    defaultMessage: "Incoterms",
  },
  shipmentIncotermsCode: {
    id: "shipment.column.shipmentIncotermsCode",
    defaultMessage: "Incoterms Code",
  },
  shipmentShipmentDelay: {
    id: "shipment.column.shipmentShipmentDelay",
    defaultMessage: "Shipment Delay",
  },
  shipmentDelayExpectedTime: {
    id: "shipment.column.shipmentDelayExpectedTime",
    defaultMessage: "Delay Expected Time",
  },
  shipmentDelayOriginal: {
    id: "shipment.column.shipmentDelayOriginal",
    defaultMessage: "Delay Original",
  },
  shipmentDelayOriginalTime: {
    id: "shipment.column.shipmentDelayOriginalTime",
    defaultMessage: "Delay Original Time",
  },
  shipmentExpectedDescription: {
    id: "shipment.column.shipmentExpectedDescription",
    defaultMessage: "Expected Description",
  },
  shipmentLoadingMeters: {
    id: "shipment.column.loadingMeters",
    defaultMessage: "Loading Meters",
  },
  shipmentRealDescription: {
    id: "shipment.column.realDescription",
    defaultMessage: "Real Description",
  },
  shipmentCustomerReference: {
    id: "shipment.column.CustomerReference",
    defaultMessage: "Customer Reference",
  },
  shipmentReadyForImportClearance: {
    id: "shipment.column.ReadyForImportClearance",
    defaultMessage: "Ready for Import Clearance",
  },
  shipmentFeatureNames: {
    id: "shipment.column.featureNames",
    defaultMessage: "Feature Names",
  },
  shipmentProductName: {
    id: "shipment.column.productName",
    defaultMessage: "Product Name",
  },
  shipmentOperationalIrregularity: {
    id: "shipment.column.OperationalIrregularity",
    defaultMessage: "Exception",
  },
  shipmentShipmentType: {
    id: "shipment.column.ShipmentType",
    defaultMessage: "Shipment Type",
  },
  shipmentSLA: { id: "shipment.column.SLA", defaultMessage: "SLA" },
  shipmentShipperReference: {
    id: "shipment.column.ShipperReference",
    defaultMessage: "Shipper Reference",
  },
  shipmentConsigneeReference: {
    id: "shipment.column.ConsigneeReference",
    defaultMessage: "Consignee Reference",
  },
  shipmentCO2WtWFootprint: {
    id: "shipment.column.CO2WtWFootprint",
    defaultMessage: "Total Carbon Footprint",
  },
  shipmentCO2WtWEfficiency: {
    id: "shipment.column.CO2WtWEfficiency",
    defaultMessage: "Main Haul Efficiency",
  },
  shipmentIrregularities: {
    id: "shipment.column.irregularities",
    defaultMessage: "Exceptions",
  },
  shipmentListReportExcel: {
    id: "shipment.excel.shipmentListReport",
    defaultMessage: "Shipment List Report",
  },
  generatedByExcel: {
    id: "shipment.excel.generatedBy",
    defaultMessage: "Generated by myDHLi Track at:",
  },
  filterCriteriaExcel: {
    id: "shipment.excel.filterCriteria",
    defaultMessage: "Filter Criteria",
  },
  paginationInfo: {
    id: "shipment.pagination.info",
    defaultMessage: "{shipments} of {total} shipments",
  },
  loadMoreButton: {
    id: "shipment.button.loadMore",
    defaultMessage: "Load More",
  },
  allLoaded: {
    id: "shipment.allLoaded",
    defaultMessage:
      "All {total, plural, one {# shipment} other {# shipments}} loaded.",
  },
  shipmentJourneyTitleRoute: {
    id: "shipment.column.shipmentJourneyTitle.route",
    defaultMessage: "Route",
  },
  shipmentJourneyTitleDetails: {
    id: "shipment.column.shipmentJourneyTitle.details",
    defaultMessage: "Details",
  },
  shipmentVolumeUnit: {
    id: "shipment.column.shipmentVolumeUnit",
    defaultMessage: "Volume Unit",
  },
  shipmentWeightUnit: {
    id: "shipment.column.shipmentWeightUnit",
    defaultMessage: "Weight Unit",
  },
  shipmentGoBackButton: {
    id: "shipment.button.goBack",
    defaultMessage: "Go Back",
  },
  completed: {
    id: "shipment.printDetail.event.completed",
    defaultMessage: "Completed",
  },
  planned: {
    id: "shipment.printDetail.event.planned",
    defaultMessage: "Planned",
  },
});
