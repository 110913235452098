/* eslint-disable react-hooks/exhaustive-deps */ // TODO remove this and handle this independently in each hook
import { ReactComponent as SearchIcon } from "assets/icons/myDHLi/search.svg";
import SVGWrapper from "components/atoms/SVGWrapper";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  setQuotesFilter,
  setBookingsListFilter,
  setCDZFilter,
} from "redux/userSettings/actions";
import styled from "styled-components";
import { getRem } from "../../../utils/css";

const FilterItemWrapper = styled.div`
  width: ${getRem(250)};
  display: flex;
`;
const SInput = styled.input`
  flex: 1 1 70%;
  border: none;
  padding: ${getRem(10)};
  padding-left: 0;
  :focus {
    outline: none;
  }
`;

const SBtn = styled.button`
  flex: 0 0 auto;
  padding: ${getRem(10)};
  cursor: pointer;
  border: none;
  background: none;
  :focus {
    outline: none;
  }
`;

const FreeText = (props) => {
  const [searchVal, setSearchVal] = useState("");
  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current.focus();
  }, []);
  const applyFilter = (val) => {
    if (props.module === "quotes") {
      props.setQuotesFilter(props.filter, val || searchVal);
    } else if (props.module === "bookings") {
      props.setBookingsListFilter(props.filter, val || searchVal);
    } else if (props.module === "cdz") {
      props.setCDZFilter(props.filter, val || searchVal);
    }
    props.onOutsideClick();
  };
  const handleEnter = useCallback((e) => {
    if (e.key === "Enter") {
      applyFilter(e.target.value);
    }
    if (e.key === "Escape") {
      props.onOutsideClick();
    }
  }, []);
  return (
    <FilterItemWrapper>
      <SBtn
        onClick={() => {
          applyFilter();
        }}
      >
        <SVGWrapper>
          <SearchIcon />
        </SVGWrapper>
      </SBtn>
      <SInput
        type="text"
        name="freeText"
        placeholder="Enter text to filter"
        ref={inputRef}
        onChange={(e) => {
          setSearchVal(e.target.value);
        }}
        onKeyDown={handleEnter}
        value={searchVal}
        autoComplete="off"
      />
    </FilterItemWrapper>
  );
};
FreeText.defaultProps = {
  module: "shipments",
};
FreeText.propTypes = {
  module: PropTypes.string,
};

export default connect(
  (state) => ({
    userSettings: state.userSettings,
  }),
  { setQuotesFilter, setBookingsListFilter, setCDZFilter }
)(FreeText);
