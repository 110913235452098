import { cdzColumns } from "constants/cdzColumns";
import { quotesColumns } from "constants/quotesColumns";
import omit from "lodash/omit";
import { bookingsDraftTabColumns } from "routes/Bookings/BookingDraftsTab/BookingDraftsTab.constants";
import { bookingsTabColumns } from "routes/Bookings/BookingsTab/BookingsTab.constants";
import get from "utils/get";
import actionTypes from "./action-types";
import { changeColumnsOrder, changeBookingsColumnsOrder } from "./helpers";

export const initialState = {
  visibilityScope: {},
  quotesColumns: quotesColumns,
  bookings: bookingsTabColumns,
  bookingsDraft: bookingsDraftTabColumns,
  cdzColumns: cdzColumns,
  searchKeywords: [],
  downloaded: [""],
  quotesFilter: {},
  bookingsListFilter: {},
  cdzFilter: {},
  corporatePartners: [],
  analyticsTimeStamp: null,
  searchPhrases: [],
  gettingCorporatePartners: false,
  quoteExportColumns: quotesColumns,
  cdzExportColumns: cdzColumns,
  latestTCAccepted: true,
  locale: "",
  readOnly: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_VISIBILITY_SCOPE:
      return {
        ...state,
        visibilityScope: action.payload,
      };

    case actionTypes.SET_QUOTES_FILTER:
      return {
        ...state,
        quotesFilter: {
          ...state.quotesFilter,
          [action.payload.key]: action.payload.value,
        },
      };
    case actionTypes.UNSET_QUOTES_FILTER:
      return {
        ...state,
        quotesFilter: omit({ ...state.quotesFilter }, action.payload.key),
      };

    case actionTypes.RESET_QUOTES_FILTER:
      return {
        ...state,
        quotesFilter: {},
      };
    case actionTypes.REPLACE_QUOTES_FILTER:
      return {
        ...state,
        searchKeywords: [],
        quotesFilter: action.payload,
      };

    case actionTypes.SET_BOOKINGS_LIST_FILTER:
      return {
        ...state,
        bookingsListFilter: {
          ...state.bookingsListFilter,
          [action.payload.key]: action.payload.value,
        },
      };
    case actionTypes.UNSET_BOOKINGS_LIST_FILTER:
      return {
        ...state,
        bookingsListFilter: omit(
          { ...state.bookingsListFilter },
          action.payload.key.uniqueValueColumnName
        ),
      };

    case actionTypes.RESET_BOOKINGS_LIST_FILTER:
      return {
        ...state,
        bookingsListFilter: {},
      };

    case actionTypes.REPLACE_BOOKINGS_LIST_FILTER:
      return {
        ...state,
        searchKeywords: [],
        bookingsListFilter: action.payload,
      };

    case actionTypes.SET_CDZ_FILTER:
      return {
        ...state,
        cdzFilter: {
          ...state.cdzFilter,
          [action.payload.key]: action.payload.value,
        },
      };
    case actionTypes.UNSET_CDZ_FILTER:
      return {
        ...state,
        cdzFilter: omit({ ...state.cdzFilter }, action.payload.key),
      };

    case actionTypes.RESET_CDZ_FILTER:
      return {
        ...state,
        cdzFilter: {},
      };
    case actionTypes.REPLACE_CDZ_FILTER:
      return {
        ...state,
        cdzFilter: action.payload,
      };

    case actionTypes.CHANGE_CDZ_COLUMN_ORDER: {
      return {
        ...state,
        cdzColumns: changeColumnsOrder(state.cdzColumns, action.payload),
      };
    }
    case actionTypes.RESET_CDZ_COLUMN_ORDER:
      return {
        ...state,
        cdzColumns: [...initialState.cdzColumns],
      };

    case actionTypes.MARK_AS_DOWNLOADED:
      return {
        ...state,
        downloaded: [...get(state, "downloaded", []), action.payload],
      };

    case actionTypes.RESET_VISIBILITY_SCOPE:
      return {
        ...state,
        visibilityScope: {},
      };
    case actionTypes.LOAD_COLUMN_PREFERENCES: {
      return {
        ...state,
        gettingData: true,
      };
    }
    case actionTypes.SAVE_COLUMN_PREFERENCES: {
      return {
        ...state,
        [action.payload.column]: [...action.payload.columnList],
        gettingData: false,
        [action.payload.column + "PreferencesLoaded"]: true,
      };
    }

    case actionTypes.CHANGE_QUOTES_COLUMN_ORDER: {
      return {
        ...state,
        quotesColumns: changeColumnsOrder(state.quotesColumns, action.payload),
      };
    }

    case actionTypes.INIT_BOOKINGS: {
      return { ...state, bookings: action.payload };
    }

    case actionTypes.INIT_BOOKINGS_DRAFTS: {
      return { ...state, bookingsDraft: action.payload };
    }

    case actionTypes.CHANGE_BOOKINGS_COLUMN_ORDER: {
      return {
        ...state,
        bookings: changeBookingsColumnsOrder(state.bookings, action.payload),
      };
    }

    case actionTypes.CHANGE_DRAFT_BOOKINGS_COLUMN_ORDER: {
      return {
        ...state,
        bookingsDraft: changeBookingsColumnsOrder(
          state.bookingsDraft,
          action.payload
        ),
      };
    }

    case actionTypes.RESET_BOOKINGS_COLUMN_ORDER:
      return {
        ...state,
        bookings: initialState.bookings,
      };

    case actionTypes.RESET_DRAFT_BOOKINGS_COLUMN_ORDER:
      return {
        ...state,
        bookingsDraft: initialState.bookingsDraft,
      };

    case actionTypes.RESET_QUOTES_COLUMN_ORDER:
      return {
        ...state,
        quotesColumns: [...initialState.quotesColumns],
      };

    case actionTypes.GET_CORPORATE_PARTNERS:
      return {
        ...state,
        gettingCorporatePartners: true,
      };
    case actionTypes.GET_CORPORATE_PARTNERS_SUCCESS:
      return {
        ...state,
        corporatePartners: action.payload,
        gettingCorporatePartners: false,
      };
    case actionTypes.GET_CORPORATE_PARTNERS_ERROR:
      return {
        ...state,
        gettingCorporatePartners: false,
      };

    case actionTypes.UPDATE_ANALYTICS_TIMESTAMP:
      return {
        ...state,
        analyticsTimeStamp: action.payload,
      };

    case actionTypes.UPDATE_CDZ_EXPORT_COLUMNS:
      return {
        ...state,
        cdzExportColumns: action.payload,
      };

    case actionTypes.UPDATE_QUOTE_EXPORT_COLUMNS:
      return {
        ...state,
        quoteExportColumns: action.payload,
      };

    case actionTypes.UPDATE_LATEST_TC_ACCEPTED:
      return {
        ...state,
        latestTCAccepted: action.payload !== false,
      };

    case actionTypes.SET_LOCALE:
      return {
        ...state,
        locale: action.payload,
      };

    case actionTypes.SET_ANALYTICS_METHOD:
      return {
        ...state,
        analyticsMethod: action.payload?.method,
        analyticsMethodSelectedBy: action.payload?.selectedBy,
      };

    case actionTypes.RESET_ALL:
      return {
        ...initialState,
      };
    case actionTypes.UPDATE_READ_ONLY:
      return {
        ...state,
        readOnly: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
