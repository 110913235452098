const baseType = "USER_SETTINGS";

const types = {
  LOAD_COLUMN_PREFERENCES: `${baseType}/LOAD_COLUMN_PREFERENCES`,
  SAVE_COLUMN_PREFERENCES: `${baseType}/SAVE_COLUMN_PREFERENCES`,
  LOAD_QUOTES_COLUMN_PREFERENCES: `${baseType}/LOAD_QUOTES_COLUMN_PREFERENCES`,
  SAVE_VISIBILITY_SCOPE: `${baseType}/SAVE_VISIBILITY_SCOPE`,
  LOAD_CORPORATE_PARTNER_PREFERENCE: `${baseType}/LOAD_CORPORATE_PARTNER_PREFERENCE`,
  RESET_VISIBILITY_SCOPE: `${baseType}/RESET_VISIBILITY_SCOPE`,
  CHANGE_BOOKINGS_COLUMN_ORDER: `${baseType}/CHANGE_BOOKINGS_COLUMN_ORDER`,
  CHANGE_DRAFT_BOOKINGS_COLUMN_ORDER: `${baseType}/CHANGE_DRAFT_BOOKINGS_COLUMN_ORDER`,
  RESET_DRAFT_BOOKINGS_COLUMN_ORDER: `${baseType}/RESET_DRAFT_BOOKINGS_COLUMN_ORDER`,
  CHANGE_QUOTES_COLUMN_ORDER: `${baseType}/CHANGE_QUOTES_COLUMN_ORDER`,
  RESET_QUOTES_COLUMN_ORDER: `${baseType}/RESET_QUOTES_COLUMN_ORDER`,
  RESET_BOOKINGS_COLUMN_ORDER: `${baseType}/RESET_BOOKINGS_COLUMN_ORDER`,
  CHANGE_CDZ_COLUMN_ORDER: `${baseType}/CHANGE_CDZ_COLUMN_ORDER`,
  RESET_CDZ_COLUMN_ORDER: `${baseType}/RESET_CDZ_COLUMN_ORDER`,
  SET_QUOTES_FILTER: `${baseType}/SET_QUOTES_FILTER`,
  UNSET_QUOTES_FILTER: `${baseType}/UNSET_QUOTES_FILTER`,
  RESET_QUOTES_FILTER: `${baseType}/RESET_QUOTES_FILTER`,
  REPLACE_QUOTES_FILTER: `${baseType}/REPLACE_QUOTES_FILTER`,
  SET_CDZ_FILTER: `${baseType}/SET_CDZ_FILTER`,
  UNSET_CDZ_FILTER: `${baseType}/UNSET_CDZ_FILTER`,
  RESET_CDZ_FILTER: `${baseType}/RESET_CDZ_FILTER`,
  REPLACE_CDZ_FILTER: `${baseType}/REPLACE_CDZ_FILTER`,
  UPDATE_CDZ_EXPORT_COLUMNS: `${baseType}/UPDATE_CDZ_EXPORT_COLUMNS`,
  MARK_AS_DOWNLOADED: `${baseType}/MARK_AS_DOWNLOADED`,
  GET_CORPORATE_PARTNERS: `${baseType}/GET_CORPORATE_PARTNERS`,
  GET_CORPORATE_PARTNERS_SUCCESS: `${baseType}/GET_CORPORATE_PARTNERS_SUCCESS`,
  GET_CORPORATE_PARTNERS_ERROR: `${baseType}/GET_CORPORATE_PARTNERS_ERROR`,
  UPDATE_ANALYTICS_TIMESTAMP: `${baseType}/UPDATE_ANALYTICS_TIMESTAMP`,
  UPDATE_QUOTE_EXPORT_COLUMNS: `${baseType}/UPDATE_QUOTE_EXPORT_COLUMNS`,
  UPDATE_LATEST_TC_ACCEPTED: `${baseType}/UPDATE_LATEST_TC_ACCEPTED`,
  SET_LOCALE: `${baseType}/SET_LOCALE`,
  SET_ANALYTICS_METHOD: `${baseType}/SET_ANALYTICS_METHOD`,
  RESET_ALL: `${baseType}/RESET_ALL`,
  RESET_BOOKINGS_LIST_FILTER: `${baseType}/RESET_BOOKINGS_LIST_FILTER`,
  SET_BOOKINGS_LIST_FILTER: `${baseType}/SET_BOOKINGS_LIST_FILTER`,
  UNSET_BOOKINGS_LIST_FILTER: `${baseType}/UNSET_BOOKINGS_LIST_FILTER`,
  REPLACE_BOOKINGS_LIST_FILTER: `${baseType}/REPLACE_BOOKINGS_LIST_FILTER`,
  INIT_BOOKINGS: `${baseType}/INIT_BOOKINGS`,
  INIT_BOOKINGS_DRAFTS: `${baseType}/INIT_BOOKINGS_DRAFTS`,
  UPDATE_READ_ONLY: `${baseType}/UPDATE_READ_ONLY`,
};

export default types;
